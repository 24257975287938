<template>
  <div class="card">
    <div style="display: flex;margin-bottom: 10px">
      <Button class="p-mr-2" @click="addOrUpdate(null)">添加模板</Button>
      <InputText class="p-mr-2" placeholder="请输入标题" v-model="name"></InputText>
      <Button @click="getData">查询</Button>
      <Button @click="showInvitePeople" :class="{'p-disabled':selectedItems.length==0}" label="消息接收人员"
              class="p-ml-2"/>
    </div>
    <data-table :value="list" v-model:selection="selectedItems" dataKey="id" :auto-layout="true">
      <template #empty><p style="text-align: center;">暂无数据</p></template>
      <Column selectionMode="multiple"></Column>
      <Column field="title" header="标题"></Column>
      <Column field="content" header="内容">
        <template #body="d">
          <div v-html="d.data.content"></div>
        </template>
      </Column>
      <Column header="是否默认" field="defaultTemplate">
        <template #body="d">
          {{ d.data.defaultTemplate == 1 ? '是' : '否' }}
        </template>
      </Column>
      <Column header="消息范围">
        <template #body="d">
          {{ d.data.allUser == 1 ? '全员消息' : '部分消息' }}
        </template>
      </Column>
      <column header="操作">
        <template #body="d">
          <Button class="p-button-sm p-mr-2" @click="setDefaultTemplate(d.data)">设为默认</Button>
          <Button class="p-button-sm p-mr-2" @click="addOrUpdate(d.data)">编辑</Button>
          <Button class="p-button-sm p-mr-2" @click="gotoView(d.data)">接受人员列表</Button>
          <Button class="p-button-danger p-button-sm" @click="deleteItem(d.data)">删除</Button>
        </template>
      </column>
    </data-table>
    <Paginator @page="changePage" :rows="page.size" :totalRecords="page.total" :rowsPerPageOptions="[5,10,25]"
               currentPageReportTemplate="显示 {first} 到 {last} 条记录，总计 {totalRecords} 记录"
               template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"></Paginator>
    <add-or-update-template ref="addOrUpdateDialog" v-if="show" v-on:close="closeDialog"></add-or-update-template>

    <Dialog v-model:visible="deleteDisplay" header="删除" style="width: 300px">
      <p>确认要删除模板{{ item.title }}？</p>
      <template #footer>
        <Button label="是" icon="pi pi-check" class="p-button-sm" @click="submitDelete" autofocus/>
        <Button label="否" icon="pi pi-times" @click="deleteDisplay=false" class="p-button-text p-button-sm"/>
      </template>
    </Dialog>
    <InvitePeople v-on:close="showPeopleList=false" v-if="showPeopleList" ref="InvitePeople"></InvitePeople>
    <Toast/>
  </div>
</template>

<script>
import AddOrUpdateTemplate from "@/views/platform/questionnaire/addOrUpdateTemplate";
import InvitePeople from "@/views/modules/public/InvitePeople";

export default {
  name: "messageTemplate",
  components: {AddOrUpdateTemplate, InvitePeople},
  data() {
    return {
      list: [],
      page: {
        total: 0,
        current: 1,
        size: 10,
      },
      show: false,
      item: {},
      deleteDisplay: false,
      name: null,
      selectedItems: [],
      showPeopleList: false
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      this.$http.get('/survey/getMessageTemplateList', {
        params: {
          current: this.page.current,
          size: this.page.size,
          name: this.name
        }
      }).then((res) => {
        console.log(res.data)
        this.page.total = res.data.total
        this.list = res.data.records
      })
    },
    changePage(event) {
      this.page.size = event.rows
      this.page.current = event.page + 1
      this.getData()
    },
    addOrUpdate(data) {
      this.show = true
      this.$nextTick(() => {
        this.$refs.addOrUpdateDialog.init(data)
      })
    },
    closeDialog() {
      this.show = false
      this.getData()
    },
    deleteItem(data) {
      this.deleteDisplay = true
      this.item = data
    },
    submitDelete() {
      this.$http.post('/survey/deleteTemplate/' + this.item.id).then(() => {
        this.deleteDisplay = false;
        this.$toast.add({severity: 'success', summary: '删除成功！', life: 3000});
        this.getData()
      })
    },
    setDefaultTemplate(data) {
      this.$http.post('/survey/setDefaultTemplate/' + data.id).then(() => {
        this.$toast.add({severity: 'success', summary: '修改成功！', life: 3000});
        this.getData()
      })
    },
    showInvitePeople() {
      this.showPeopleList = true
      this.$nextTick(()=>{
        this.$refs.InvitePeople.init(4,this.selectedItems)
      })
    },
    gotoView(data) {
      this.$router.push('/participants?id=' + data.id + '&tableType=4')
    },
  }

}
</script>

<style scoped>

</style>