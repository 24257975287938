<template>
  <Dialog v-model:visible="display"
          :header="form.id?'编辑模板':'添加模板'"
          @hide="closeDialog">
    <div class="fromItem">
      <label>标题：</label>
      <InputText v-model="form.title"/>
    </div>
    <div class="fromItem">
      <label>全员消息：</label>
      <InputSwitch v-model="form.allUser" @change="showSelectList"/>
    </div>
    <!--    <Button v-if="!form.allUser" @click="showSelectList">选择接收人员列表</Button>-->
    <div class="fromItem">
      <label>内容：</label>
      <my-editor :value="form.content" v-on:input="setContent"></my-editor>
      <span v-if="hasUrl" style="color: red;">检查内容是否包含"{url}"</span>
    </div>
    <p style="text-indent: 2em">其中以{name}代替问卷名称，以{url}代替问卷地址链接，内容中必须包含问卷链接。</p>
    <div class="fromItem" style="text-align: right">
      <Button @click="submit" class="p-mr-4">提交</Button>
      <Button @click="display=false" class="p-mr-4">取消</Button>
    </div>
    <select-people v-on:close="closeSelect" v-on:submit="submitSelect"
                   ref="selectList" v-if="!form.allUser&&select"></select-people>

  </Dialog>
  <Toast/>
</template>

<script>
import myEditor from "@/views/backend/myEditor";
import SelectPeople from "@/views/platform/questionnaire/SelectPeople";

export default {
  components: {SelectPeople, myEditor},
  name: "addOrUpdateTemplate",
  data() {
    return {
      display: false,
      select: false,
      hasUrl: false,
      form: {
        id: null,
        title: null,
        content: null,
        allUser: true,
        list: []
      }

    }
  },
  methods: {
    init(data) {
      this.display = true
      if (data != null) {
        this.form = data
        if (data.allUser == 1) {
          this.form.allUser = true
        } else {
          this.form.allUser = false
        }
        console.log(this.form.id)
      }
    },
    closeDialog() {
      this.display = false
      this.$emit('close')
    },
    setContent(data) {
      this.form.content = data
    },
    submit() {
      if (this.form.content.indexOf('{url}') !== -1) {
        this.hasUrl = false
        if (this.form.allUser) {
          this.form.allUser = 1
        } else {
          this.form.allUser = 0
        }
        this.$http.post('/survey/addOrUpdateTemplate', this.form).then(() => {
          this.$toast.add({severity: 'success', summary: this.form.id ? '修改成功' : '添加成功', life: 3000})
          this.closeDialog()
        })

      } else {
        this.hasUrl = true
      }
    },
    showSelectList() {
      if (!this.form.allUser) {
        this.select = true;
        this.$nextTick(() => {
          this.$refs.selectList.init()
        })
      }
    },
    closeSelect() {
      this.select = false
    },
    submitSelect(list) {
      this.select = false
      this.form.list = list
      // for (let i=0;i<list.length;i++){
      //   console.log(list[i].id)
      // }
    }
  }
}
</script>

<style scoped>
.fromItem {
  width: 100%;
  padding-bottom: 10px;
}

.fromItem input {
  width: 50%;
}
</style>