<template>
  <Dialog header="选择接收人员" v-model:visible="display">
    <data-table :value="list" v-model:selection="peopleList" dataKey="id"
                :auto-layout="true">
      <template #empty>
        <p>暂无数据...</p>
      </template>
      <template #header>
        <div style="display: flex">
          <InputText type="text" v-model="name" placeholder="查找用户名" @change="getData"></InputText>
          <Dropdown class="p-ml-2" v-model="industry" :options="industryList" optionLabel="name"
                    placeholder="专业领域" option-value="name" :show-clear="true"/>
          <Dropdown class="p-ml-2" v-model="state" :options="stateList" optionLabel="name"
                    placeholder="类型" option-value="code" :show-clear="true"/>
          <InputNumber class="p-ml-2" :useGrouping="false" v-model="phone" placeholder="查找电话"></InputNumber>
          <Button class="p-ml-2" @click="getData">查询</Button>
        </div>
      </template>
      <Column selectionMode="multiple"></Column>
      <Column field="userName" header="名称"></Column>
      <Column field="pic" header="头像">
        <template #body="a">
          <img v-if="a.data.pic" style="width: 80px" :src="picUrl+a.data.pic">
        </template>
      </Column>
      <Column field="postTime" header="添加时间">
        <template #body="a">
          {{ new Date(a.data.postTime).toLocaleString() }}
        </template>
      </Column>
      <Column field="phone" header="电话"></Column>
      <Column field="sex" header="性别"></Column>
      <Column field="industry" header="专业领域"></Column>
      <Column field="degree" header="学历"></Column>
      <Column field="expert" header="类型">
        <template #body="a">
          {{ a.data.expert == 0 ? '普通用户' : (a.data.expert == 1 ? '专家' : (a.data.expert == 2 ? '企业' : '')) }}
        </template>
      </Column>
    </data-table>
    <Paginator @page="changePage" :rows="page.size" :totalRecords="page.total" :rowsPerPageOptions="[5,10,25]"
               currentPageReportTemplate="显示 {first} 到 {last} 条记录，总计 {totalRecords} 记录"
               template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"></Paginator>
    <template #footer>
      <Button @click="submitList">确认</Button>
      <Button @click="closeDialog">取消</Button>
    </template>
  </Dialog>
</template>

<script>
export default {
  name: "SelectPeople",
  data() {
    return {
      peopleList: [],
      display: false,
      name: null,
      state: null,
      phone: null,
      industry: null,
      picUrl: this.$global_msg.imgurl,
      list: null,
      industryList: [],
      stateList: [
        {name: '普通用户', code: 0},
        {name: '专家', code: 1},
        {name: '企业用户', code: 2}
      ],
      page: {
        size: 10,
        current: 1,
        total: 0
      }
    }
  },
  mounted() {

  },
  methods: {
    init() {
      this.display = true
      this.getData()
      this.getIndustry()
    },
    getData() {
      this.$http.get('/publicUser/getList', {
        params: {
          current: this.page.current,
          size: this.page.size,
          name: this.name,
          state: this.state,
          phone: this.phone,
          industry: this.industry
        }
      }).then((res) => {
        //console.log(res.data)
        this.page.total = res.data.total
        this.list = res.data.records
      })
    },
    getIndustry() {
      this.$http.get('/dictionary/selectValue', {params: {key: 'zhuanyelingyu'}}).then((res) => {
        let list = res.data.myValue.split(",");
        let resList = []
        for (let i in list) {
          let item = {}
          item.name = list[i]
          resList.push(item)
        }

        this.industryList = resList;
      })
    },
    changePage(event) {
      this.page.size = event.rows
      this.page.current = event.page + 1
      this.getData()
    },
    closeDialog(){
      this.display=false
      this.$emit('close')
    },
    submitList(){
      this.display=false
      this.$emit('submit',this.peopleList)
    }
  }
}
</script>

<style scoped>

</style>